import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay3to5/1.jpg';
// import p2 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay3to5/2.jpg';
// import p3 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay3to5/3.jpg';
// import p4 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay3to5/4.jpg';
// import p5 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay3to5/5.jpg';
// import p6 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay3to5/6.jpg';


import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import EcoClubSidebar2024 from '../EnvironmentDay2023/EcoClubSidebar2024';


const EcoAssociationClass3to5 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay3to5/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay3to5/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay3to5/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay3to5/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay3to5/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/association/EcoClub/EnvironmentDay3to5/6.webp`;


  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },

  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Environment Day 
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 3 to 5  &  Date: 28 June 2024
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        World Environment Day is celebrated annually in the month of June to raise awareness about environmental issues and encourage global action to protect our planet. This year, National Public School, Yeshwanthpur, organised a series of activities for students of Classes 3-5 to commemorate World Environment Day. 
        <br></br>
        The aim was to foster creativity and raise awareness about environmental issues, to develop artistic skills reinforcing key environmental messages, instill a sense of responsibility towards the environment and empathy towards living beings.
        <br></br>
        The students participated in the activity with zeal and enthusiasm. It motivated them to  
        feel empowered and motivated to take concrete actions in daily life and within
        communities to protect and conserve the environment, contributing to more sustainable  resilient planet.

        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
      "Protecting Our Planet: Embracing Sustainability for a Greener Future!" 

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 200 : 100}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <EcoClubSidebar2024/>
             </Box>
             {/* <Box marginBottom={4}>
               <Sidebar />
             </Box> */}

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default EcoAssociationClass3to5;